
<template>
    <component :is="discountCouponData === undefined ? 'div' : 'b-card'">

        <!-- Alert: No item found -->
        <b-alert
            variant="danger"
            :show="discountCouponData === undefined"
        >
            <h4 class="alert-heading">Error al obtener los datos del cupón de descuento</h4>
            <div class="alert-body">
                No se encontró ningún producto con este id. Verificar en la
                <b-link
                    class="alert-link"
                    :to="{ name: 'discountcoupons'}"
                >
                    Lista de cupones de descuento
                </b-link>
                para buscar otro cupón de descuento.
            </div>
        </b-alert>

        <b-tabs
            v-if="discountCouponData"
            pills
        >
      
            <!-- Tab: Information -->
            <b-tab>
                <template #title>
                    <feather-icon
                        icon="InfoIcon"
                        size="16"
                        class="mr-0 mr-sm-50"
                    />
                    <span class="d-none d-sm-inline">Información</span>
                </template>
                <discount-coupon-edit-tab-information
                    class="mt-2 pt-75"
                    :discount-coupon-data="discountCouponData"
                />
            </b-tab>
      
        </b-tabs>
    </component>
</template>

<script>

    import DiscountCouponEditTabInformation from './DiscountCouponEditTabInformation.vue';
    import {BAlert, BCard, BLink, BTab, BTabs} from 'bootstrap-vue';
    import {onUnmounted, ref} from '@vue/composition-api';
    import discountCouponStoreModule from '../discountCouponStoreModule';
    import router from '@/router';
    import store from '@/store';

    export default {
        components: {
            BTab,
            BTabs,
            BCard,
            BAlert,
            BLink,
    
            DiscountCouponEditTabInformation
        },
        setup() {
            const discountCouponData = ref(null);

            const DISCOUNT_COUPON_APP_STORE_MODULE_NAME = 'app-discountcoupon';

            // Register module
            if (!store.hasModule(DISCOUNT_COUPON_APP_STORE_MODULE_NAME)) store.registerModule(DISCOUNT_COUPON_APP_STORE_MODULE_NAME, discountCouponStoreModule);

            // UnRegister on leave
            onUnmounted(() => {
                if (store.hasModule(DISCOUNT_COUPON_APP_STORE_MODULE_NAME)) store.unregisterModule(DISCOUNT_COUPON_APP_STORE_MODULE_NAME)
            });

            store.dispatch('app-discountcoupon/fetchDiscountCoupon', { id: router.currentRoute.params.id })
                .then(response => {
                    if (response.data === 'error') {
                        discountCouponData.value = undefined
                    } else {
                        discountCouponData.value = response.data;
                    }
                })
                .catch(error => {
                    if (error.response.status === 404) {
                        discountCouponData.value = undefined
                    }
                });

            return {
                discountCouponData
            }
        }
    }

</script>