<template>
    <div>

        <!-- Form: Info Form -->
        <validation-observer ref="simpleRules">
            <b-form>
                <b-row>

                    <!-- Generate Coupon Code Button -->
                    <b-col md="12">
                        <b-button
                            @click="generateDicountCouponCode"
                            size="sm"
                            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                            variant="primary"
                        >
                            GENERAR UN CÓDIGO DE CUPÓN
                        </b-button>
                        <hr>
                    </b-col>

                    <!-- Code -->
                    <b-col md="4">
                        <b-form-group
                            label="Código"
                            label-for="code">
                            <validation-provider
                                #default="{ errors }"
                                rules="required"
                                name="código de cupón"
                            >
                                <b-form-input
                                    id="code"
                                    v-model="discountCouponData.code"
                                    placeholder="Código de cupón ..."
                                    :state="errors.length > 0 ? false : null"
                                />
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-form-group>
                    </b-col>

                    <!-- Discount Coupon Type -->
                    <b-col md="4">
                        <b-form-group
                            label="Seleccionar tipo de descuento"
                            label-for="discount_coupon_type">
                            <validation-provider
                                #default="{ errors }"
                                rules="required"
                                name="tipo de descuento"
                            >
                                <v-select
                                    id="discount_coupon_type"
                                    v-model="discountCouponData.discount_coupon_type"
                                    :state="errors.length > 0 ? false : null"
                                    :options="types"
                                    label="name"
                                />

                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-form-group>
                    </b-col>

                    <!-- Amount -->
                    <b-col md="4">
                        <b-form-group
                            label="Importe"
                            label-for="amount">
                            <validation-provider
                                #default="{ errors }"
                                rules="required"
                                name="importe de cupón"
                            >
                                <b-form-input
                                    id="amount"
                                    v-model="discountCouponData.discountNumber"
                                    placeholder="importe del cupón ..."
                                    :state="errors.length > 0 ? false : null"
                                />
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-form-group>
                    </b-col>

                    <!-- Usage Per User Quantity -->
                    <b-col md="4">
                        <b-form-group
                            label="Límite de uso por usuario"
                            label-for="usagePerUserQuantity">

                            <b-form-input
                                type="number"
                                id="usagePerUserQuantity"
                                v-model="discountCouponData.usagePerUserQuantity"
                                placeholder="Límite de uso por usuario ..."
                            />

                        </b-form-group>
                    </b-col>

                    <!-- Usage Per Coupon Quantity -->
                    <b-col md="4">
                        <b-form-group
                            label="Límite de uso por cupón"
                            label-for="usagePerCouponQuantity">

                            <b-form-input
                                type="number"
                                id="usagePerCouponQuantity"
                                v-model="discountCouponData.usagePerCouponQuantity"
                                placeholder="Límite de uso por cupón ..."
                            />

                        </b-form-group>
                    </b-col>

                    <!-- Expiry Date -->
                    <b-col md="4">
                        <b-form-group
                            label="Fecha de caducidad"
                            label-for="expiry_date">

                            <flat-pickr
                                id="expiry_date"
                                v-model="discountCouponData.expiryDate"
                                class="form-control"
                                :config="config"
                                placeholder="YYYY-MM-DD"
                            />

                        </b-form-group>
                    </b-col>

                    <!-- Minimum Cost -->
                    <b-col md="4">
                        <b-form-group
                            label="Gasto mínimo"
                            label-for="minimumCost">

                            <b-form-input
                                type="number"
                                id="minimumCost"
                                v-model="discountCouponData.minimumCost"
                                placeholder="Gasto mínimo ..."
                            />

                        </b-form-group>
                    </b-col>
                    
                     <!-- Products on Offer -->
                    <b-col md="4">
                        <b-form-group
                            label="¿Aplica para productos con descuento?"
                            label-for="products_on_offer">
                            <validation-provider
                                #default="{ errors }"
                                rules="required"
                                name="productos con descuento"
                            >                        
                                <v-select
                                    id="products_on_offer"
                                    v-model="discountCouponData.productsOnOffer"
                                    :reduce="(option) => option.value"
                                    :state="errors.length > 0 ? false : null"
                                    :options="optionsOffer"
                                    label="label"
                                />

                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-form-group>
                    </b-col>

                    <b-col md="12">
                        <b-form-checkbox
                            class="mt-1"
                            v-model="discountCouponData.freeDelivery"
                            name="freeDelivery"
                        >
                            <strong>¿PERMITIR EL ENVÍO GRATUITO?</strong>
                        </b-form-checkbox>
                    </b-col>

                </b-row>
            </b-form>
        </validation-observer>
    
        <!-- Action Buttons -->
        <b-button
            @click="updateInformation"
            variant="primary"
            class="mb-1 mb-sm-0 mr-0 mr-sm-1 mt-2"
            :block="$store.getters['app/currentBreakPoint'] === 'xs'"
        >
            ACTUALIZAR
        </b-button>
    </div>
</template>

<script>

    import { BButtonGroup, BButton, BRow, BCol, BFormGroup, BFormInput, BForm, BFormCheckbox } from 'bootstrap-vue';
    import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
    import { ValidationProvider, ValidationObserver } from 'vee-validate';
    import { useToast } from 'vue-toastification/composition';
    import { Spanish } from 'flatpickr/dist/l10n/es';
    import flatPickr from 'vue-flatpickr-component';
    import { ref } from '@vue/composition-api';
    import Ripple from 'vue-ripple-directive';
    import { required } from '@validations';
    import vSelect from 'vue-select';
    import cc  from 'coupon-code';
    import store from '@/store';
    import axios from 'axios';

    export default {
        components: {
            ValidationProvider,
            ValidationObserver,

            BButtonGroup,
            BButton,
            BRow,
            BCol,
            BFormGroup,
            BFormInput,
            BFormCheckbox,
            BForm,
            vSelect,
            flatPickr,

            required
        },
        props: {
            discountCouponData: {
                type: Object,
                required: true
            }
        },
        directives: {
            Ripple
        },
        data (){
            return {
                types: [],
                optionsOffer: [{
                    label: 'Sí',
                    value: true
                },{
                    label: 'No',
                    value: false
                }],

                config: {
                    enableTime: false,
                    locale: Spanish
                }
            }
        },
        async beforeMount (){
            await this.getDiscountCouponTypes();
        },
        methods: {
            async getDiscountCouponTypes (){
                let response = await axios.get(`${process.env.VUE_APP_HOST}/api/dashboard/discount-coupon-types/select`);
                this.types = response.data;
            },
            generateDicountCouponCode (){
                this.discountCouponData.code = cc.generate(); 
            }
        },
        setup(props) {

            // Use Toast
            const toast = useToast();

            const simpleRules = ref(null);

            const updateInformation = () => {
                simpleRules.value.validate().then(success => {
                    if (success) {

                        let data = {
                            code: props.discountCouponData.code,
                            discount_coupon_type: props.discountCouponData.discount_coupon_type._id,
                            discountNumber: props.discountCouponData.discountNumber,
                            usagePerUserQuantity: props.discountCouponData.usagePerUserQuantity ? props.discountCouponData.usagePerUserQuantity : null,
                            usagePerCouponQuantity: props.discountCouponData.usagePerCouponQuantity ? props.discountCouponData.usagePerCouponQuantity : null,
                            expiryDate: props.discountCouponData.expiryDate,
                            minimumCost: props.discountCouponData.minimumCost ? props.discountCouponData.minimumCost : null,
                            freeDelivery: props.discountCouponData.freeDelivery,
                            productsOnOffer: props.discountCouponData.productsOnOffer
                        };

                        let discountCouponData = {
                            ...data
                        };

                        store.dispatch('app-discountcoupon/updateDiscountCoupon', { id: props.discountCouponData._id, discountCouponData})
                            .then( (response) => {
                                toast({
                                    component: ToastificationContent,
                                    props: {
                                        title: response.data.message,
                                        icon: 'CheckIcon',
                                        variant: 'success'
                                    }
                                });
                            })
                            .catch( (err) => {
                                let message = err.response.data.message ? err.response.data.message : 'Error al actualizar la información del cupón de descuento.';
                                toast({
                                    component: ToastificationContent,
                                    props: {
                                        title: message,
                                        icon: 'AlertTriangleIcon',
                                        variant: 'danger'
                                    }
                                });
                            });
                    }
                });
            };

            return {
                updateInformation,
                simpleRules
            }
        }
    }

</script>

<style lang="scss">
    @import '@core/scss/vue/libs/vue-select.scss';
    @import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>